export interface APIGetLoggerReportsModel {

    serial: number | null;
    product: string | null;
    startdate: Date | null;
    manufactured: string | null;
    siteid: number | null;
    loggerid: number | null;
    lcloggerid: number | null;
    loggersiteid: string | null;
    sitesitename: string | null;
    sitesitenameuserfriendly: string;
    latitude:  number | null;
    longitude: number | null;
    softwareversion: string | null;
    latestbattery: number | null;
    latestsignal: number | null;
    latestconfig: string | null;
    transmissionfrequency: number | null;
    numdailydialins: number | null;
    lastconnection: Date | null;
    lastdatapoint: Date | null;
    lastconnectiondisplay: string | null;
    lastdatapointdisplay: string | null;
    alarmstate: number | null;
    alarmsummarymessage: string | null;

    /*
    alarmmessage0: string | null;
    alarmmessage1: string | null;
    alarmmessage2: string | null;
    alarmmessage3: string | null;
    alarmmessage4: string | null;
    alarmmessage5: string | null;
    alarmmessage6: string | null;
    alarmmessage7: string | null;
    alarmmessage8: string | null;
    alarmmessage9: string | null;
    alarmmessage10: string | null;
    alarmmessage11: string | null;
    alarmmessage12: string | null;
    alarmmessage13: string | null;
    alarmmessage14: string | null;
    alarmmessage15: string | null;
    */

    callinstatus: string | null;
    lasteventdetails: string | null;
    networktype: string | null;
    carrier: string | null;

}

export function alarmSummary(stats: any) {

    const alarms: string[] = [];

    if (stats.alarmmessage0 && stats.alarmmessage0 != "") {
        alarms.push((stats.alarmstate & 0x0001) > 0 ? stats.alarmmessage0 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage1 && stats.alarmmessage1 != "") {
        alarms.push((stats.alarmstate & 0x0002) > 0 ? stats.alarmmessage1 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage2 && stats.alarmmessage2 != "") {
        alarms.push((stats.alarmstate & 0x0004) > 0 ? stats.alarmmessage2 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage3 && stats.alarmmessage3 != "") {
        alarms.push((stats.alarmstate & 0x0008) > 0 ? stats.alarmmessage3 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage4 && stats.alarmmessage4 != "") {
        alarms.push((stats.alarmstate & 0x0010) > 0 ? stats.alarmmessage4 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage5 && stats.alarmmessage5 != "") {
        alarms.push((stats.alarmstate & 0x0020) > 0 ? stats.alarmmessage5 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage6 && stats.alarmmessage6 != "") {
        alarms.push((stats.alarmstate & 0x0040) > 0 ? stats.alarmmessage6 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage7 && stats.alarmmessage7 != "") {
        alarms.push((stats.alarmstate & 0x0080) > 0 ? stats.alarmmessage7 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage8 && stats.alarmmessage8 != "") {
        alarms.push((stats.alarmstate & 0x0100) > 0 ? stats.alarmmessage8 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage9 && stats.alarmmessage9 != "") {
        alarms.push((stats.alarmstate & 0x0200) > 0 ? stats.alarmmessage9 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage10 && stats.alarmmessage10 != "") {
        alarms.push((stats.alarmstate & 0x0400) > 0 ? stats.alarmmessage10 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage11 && stats.alarmmessage11 != "") {
        alarms.push((stats.alarmstate & 0x0800) > 0 ? stats.alarmmessage11 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage12 && stats.alarmmessage12 != "") {
        alarms.push((stats.alarmstate & 0x1000) > 0 ? stats.alarmmessage12 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage13 && stats.alarmmessage13 != "") {
        alarms.push((stats.alarmstate & 0x2000) > 0 ? stats.alarmmessage13 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage14 && stats.alarmmessage14 != "") {
        alarms.push((stats.alarmstate & 0x4000) > 0 ? stats.alarmmessage14 + ' ACTIVE' : 'Off');
    }
    if (stats.alarmmessage0 && stats.alarmmessage15 != "") {
        alarms.push((stats.alarmstate & 0x8000) > 0 ? stats.alarmmessage15 + ' ACTIVE' : 'Off');
    }

    //const result: string | null;
    let result = "";

    for (let i = 0; i < alarms.length; i++) {
        if (alarms[i] != 'Off') result = result + alarms[i] + '\r\n' + ' || '
    }

    //    alarms.map((a, i) =>
    //  {
        
        //return (<span key={i}>{a}: {state[i]}<br /></span>)
    //})

    return result;

}

export function buildAPIGetLoggerReportsModel(r: any): APIGetLoggerReportsModel {

    let networktype = '';
    let carrierCode = '';
    let carrier = '';
    let cellId = "";
    let band = ""
    if (r.lasteventdetails != null) {
        if (r.lasteventdetails.toString().includes('Type: 9')) networktype = 'NB-IoT';
        if (r.lasteventdetails.toString().includes('Type: 7')) networktype = 'LTE-M';
        if (r.lasteventdetails.toString().includes('CellId: ')) {
           cellId = r.lasteventdetails.slice(r.lasteventdetails.indexOf('CellId: ') + 8, r.lasteventdetails.indexOf('TAC: ') -1 )  
        }
        if (r.lasteventdetails.toString().includes("Band: ")) {
            band = r.lasteventdetails.slice(r.lasteventdetails.indexOf('Band: ') + 6, r.lasteventdetails.indexOf('Sim ICCID: ') - 1)
        }
        const mccMncPattern = /MCC\+MNC:\s*(\d{5})/;
        const match = r.lasteventdetails.toString().match(mccMncPattern);       

        if (match.length > 1) {                
            carrierCode = match[1].slice(-2);
            if (carrierCode == '10' || carrierCode == '02' || carrierCode == '11') {
                carrier = "O2";
            }
            if (carrierCode == '92' || carrierCode == '07' || carrierCode == '15' || carrierCode == '77' || carrierCode == '91' || carrierCode == '89') {
                carrier = "Vodafone";
            }
            if (carrierCode == '30' || carrierCode == '31' || carrierCode == '32' || carrierCode == '33') {
                carrier = "EE";
            }
        }
    }

    return {
        serial: r.serial,
        product: r.product == null ? 'Unknown' : r.product,

        startdate: r.startdate == null ? null : r.startdate,
        manufactured: r.startdate == null ? null : r.startdate.toString(),

        siteid: r.siteid,
        loggerid: r.loggerid,
        lcloggerid: r.lcloggerid,
        loggersiteid: r.loggersiteid,
        sitesitename: r.sitesitename,
        sitesitenameuserfriendly: r.sitesitenameuserfriendly,
        latitude: r.latitude,
        longitude: r.longitude,
        softwareversion: r.softwareversion,
        latestbattery: r.latestbattery,
        latestsignal: r.latestsignal,
        latestconfig: r.latestconfig,
        transmissionfrequency: r.transmissionfrequency,
        numdailydialins: r.numdailydialins,

        lastconnection: r.lastconnection,
        lastconnectiondisplay: r.lastconnection == null ? null : r.lastconnection.toString(),

        lastdatapoint: r.lastdatapoint,
        lastdatapointdisplay: r.lastdatapoint == null ? null : r.lastdatapoint.toString(),

        alarmstate: r.alarmstate,
        //alarmsummarymessage: null,
        alarmsummarymessage: alarmSummary(r),
        /*
        alarmmessage0: r.alarmmessage0,
        alarmmessage1: r.alarmmessage0,
        alarmmessage2: r.alarmmessage0,
        alarmmessage3: r.alarmmessage0,
        alarmmessage4: r.alarmmessage0,
        alarmmessage5: r.alarmmessage0,
        alarmmessage6: r.alarmmessage0,
        alarmmessage7: r.alarmmessage0,
        alarmmessage8: r.alarmmessage0,
        alarmmessage9: r.alarmmessage0,
        alarmmessage10: r.alarmmessage0,
        alarmmessage11: r.alarmmessage0,
        alarmmessage12: r.alarmmessage0,
        alarmmessage13: r.alarmmessage0,
        alarmmessage14: r.alarmmessage0,
        alarmmessage15: r.alarmmessage0,
        */

        callinstatus: r.callinstatus,
        networktype: networktype,
        lasteventdetails: r.lasteventdetails,
        carrier: carrier,
        cellId: cellId,
        band: band


    }
}

