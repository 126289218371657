import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Modal from 'react-bootstrap/Modal'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LoggerReportDialog from "components/Reports/LoggerReportDialog";
import DeviceReportDialog from "components/Reports/DeviceReport";
import ConsumptionReport from "components/Reports/SadborowReport"
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import MaterialButton from '@mui/material/Button';

import { Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import Tooltip from '@mui/material/Tooltip';
// DevExtreme
import { DataGrid, Column as GridColumn, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter, Selection, Sorting } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { locale } from "devextreme/localization";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';


//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";
import makeAnimated from 'react-select/animated';

import { Button, Checkbox, Grid, Select } from '@mui/material';
import DeployDialog from '../../components/Device/DeployDialog';
import UndeployDialog from '../../components/Device/UndeployDialog';

import { setDeviceBackground } from '../../variables/deviceStatusColours.js';
import DeviceStatusLegend from '../../components/Device/DeviceStatusLegend';
import ToolkitTOR from '../../components/Configuration/ToolkitTOR';
import { APIGetLoggerConfigurationsModel } from '../../models/APIGetLoggerConfigurationsModel';

import ToolkitCHR from '../../components/Configuration/ToolkitCHR';
import UploadDialog from '../../components/ImageUpload/UploadDialog';

import { isLCLLogger } from 'variables/chariotProductIds';
import zIndex from '@mui/material/styles/zIndex';


const animatedComponents = makeAnimated();

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

let modalTitle = "";

const style = createStyles(styles);

// Maps to APIGetLoggersModel
interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnected: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    siteRef: string;
    deployed: string;
    sendToLogger: boolean;
    updateRequested: Date | null;
    updateConfirmed: Date | null;
    firmwareToUpdate: number | null;
    firmwareSent: Date | null;
    firmwareConfirmed: Date | null;
    gpsLatitude: number | null;
    gpsLongitude: number | null;
    configurationId: string;
    undeploy: boolean;
    shutdown: boolean;
    dormant: boolean;
    company: string;
    modelId: string;
    channelsAvail: number;
    meterConfig: number;
    favourite: boolean;
}

interface Site {
    id: number;
    SiteNameUserFriendly: string;
    SiteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
}

interface FavoriteColumnTemplateProps {
    data: {
        Favorite: boolean;
        // Add other properties as needed
    };
    onToggleFavorite: (data: any) => void;
}


interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    mapKey: string;
    deviceData: Array<DeviceData>
}

interface State {
    reportDialog: boolean;
    reportType: string;
    reportData: boolean[];
    reportEnable: boolean;
    reportContent: string;
    reportMonth: number;

    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    devices: Array<DeviceData>;
    filterValue: Array<string | string[]>;
    tempDevice: DeviceData | undefined;
    tempSite: Site | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    deleteModal: boolean;
    modal: boolean;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined } |
    {
        groupId: number | null;
        loggers: Array<number>;
        loggerId: number | null;
        showBackButton: boolean;
    }
    | undefined;
    deployModal: boolean;
    fromDate: Date | undefined;
    sites: Array<{ value: number; label: string; lastUpdate: Date }>;
    showCompany: boolean;
    currentConfig: APIGetLoggerConfigurationsModel | undefined;
    selected: Array<number>;
    chariotModal: boolean;
    imageDialog: boolean;
    selectedItemKeys: Array<number>;
    savedFavorites: Array<number>;
    newFavourites: boolean;
    reportSadborowDialog: boolean;
    reportsAvail: boolean;
    templateClicked: boolean;

}

class DeviceList extends React.Component<Props, State> {

    timer: any = null; 

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();
        locale(navigator.language);

        this.state = {
            reportDialog: false,
            reportType: "XLSX",
            reportData: [false, false, false, false, false],
            reportEnable: false,
            reportContent: "Summary",
            reportMonth: 0,
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            devices: [],
            loading: false,
            authorized: true,
            filterValue: [],
            tempDevice: undefined,
            tempSite: undefined,
            anchorEl: null,
            deleteModal: false,
            modal: false,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            deployModal: false,
            fromDate: undefined,
            sites: [],
            //showCompany: (sessionStorage.getItem('userLevel') == "identityadmin"),
            showCompany: true,
            currentConfig: undefined,
            selected: sessionStorage.getItem('selectedLoggers') ? sessionStorage.getItem('selectedLoggers').split(',').map(item => parseInt(item)) : [],
            chariotModal: false,
            imageDialog: false,
            selectedItemKeys: sessionStorage.getItem('selectedLoggers') ? sessionStorage.getItem('selectedLoggers').split(',').map(item => parseInt(item)) : [], 
            savedFavorites: [],
            newFavourites: false,
            reportSadborowDialog: false,
            reportsAvail: false,
            templateClicked: false


        };

        this.selectionChanged = this.selectionChanged.bind(this);
        this.changeOfSelectionFromReport = this.changeOfSelectionFromReport.bind(this);

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success');

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing sites', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempDevice?.id || null);
        });
    };



    async componentDidMount(): Promise<void> {
    
        this.setState({ loading: true });
        
        
            await this.setFavourites();
             
            await this.reloadData(null);
             
            
            this.setState({ loading: false });
       
        
    }
    


    
    
    setFavourites(): void {
        const favouriteString = sessionStorage.getItem('favourites')
        const favouriteLoggers = favouriteString ? favouriteString.split(',') : ''
        if (favouriteLoggers) {
            const loggersToInt = favouriteLoggers.map((item) => parseInt(item))
            this.setState({ savedFavorites: loggersToInt })
        }
        
    }
    componentWillUnmount(): void {
            const me = this
            this.setState({ savedFavorites: [] })
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userid: sessionStorage.getItem('userId'),
                    userGuid: sessionStorage.getItem('userGuid'),
                    favourites: sessionStorage.getItem('favourites')
                })
            };

            CallPostAPI(CreateUrl('/api/aquaguard/SetUserFavouritesIdString'), requestOptions)
                .then(data => {
                    if (!data)  {
                        me.createErrorNotification();
                        
                    }
                })

                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                });
            
        }
    

    gutermannModel(model:string): string {
        let label = ""
        switch (model) {
            case "CHFL":
                label = "ZL-2F"
                break
            case "CHF1":
                label = "ZL-2F-1P"
                break
            case "CHF2":
                label = "ZL-2F-2P"
                break
            case "CH1P":
                label = "ZL-1P"
                break
            case "CH2P":
                label = "ZL-2P"
                break
            default:
                label = "unknown"
        }
        return label
    }

    reloadData(editId: number | null): void {
        const deviceData = new Array<DeviceData>();

        const me = this;
        
          const  data = this.props.deviceData;
    
        if (data.length > 0) {
            // Copy the data records into deviceData, adding the clickEvent
            deviceData.length = 0; //clear dummy data
            for (let i = 0; i < data.length; i++) {
                const serial = data[i].serial;
                //const id = parseInt(data[i].id);
                this.state.savedFavorites.includes(data[i].id) ? data[i].favourite = true : data[i].favourite = false
                if (window.location.hostname.includes('zonelog.net')) {
                    data[i].model = this.gutermannModel(data[i].model)
                }
                const rec: DeviceData = {
                    id: data[i].id,
                    serial: serial,
                    site: data[i].site,
                    model: data[i].model,
                    channels: data[i].channels,
                    lastConnected: data[i].lastConnected,
                    alarmState: data[i].alarmState,
                    siteId: data[i].siteId,
                    siteRef: data[i].siteName,
                    siteName: data[i].siteName,
                    deployed: data[i].deployed,
                    sendToLogger: data[i].sendToLogger,
                    updateRequested: data[i].updateRequested,
                    updateConfirmed: data[i].updateConfirmed,
                    firmwareToUpdate: data[i].firmwareToUpdate,
                    firmwareSent: data[i].firmwareSent,
                    firmwareConfirmed: data[i].firmwareConfirmed,
                    gpsLatitude: data[i].gpsLatitude,
                    gpsLongitude: data[i].gpsLongitude,
                    configurationId: data[i].configurationId,
                    undeploy: data[i].undeploy,
                    shutdown: data[i].shutdown,
                    dormant: data[i].dormant,
                    company: data[i].company,
                    modelId: data[i].modelId,
                    channelsAvail: data[i].channelsAvail,
                    meterConfig: data[i].meterConfig,
                    favourite: data[i].favourite
                };

                deviceData.push(rec);
                deviceData.sort((a, b) => (a.favourite === b.favourite) ? 0 : a.favourite ? -1 : 1);

            }

            CallGetAPI(CreateUrl(`/api/aquaguard/reportsforcompany?companyId=${sessionStorage.getItem('companyId')}`), {})
                .then(res => {
                    if (res.length > 0) {
                        this.setState({ reportsAvail: true })
                    }
                })
            me.setState(
                {
                    devices: deviceData,
                    tableHidden: false

                })

            if (editId != null) {
                const device = me.state.devices.filter(d => d.id == editId);
                this.setState(
                    {
                        tempDevice: device[0],
                        modal: !me.state.deployModal,
                        anchorEl: null,
                    });

            }
        }
                
                else {
                    me.setState(
                        {
                            devices: [],
                            tableHidden: false,
                            loading: false
                        })
                    
                }
            }

            
    




    getSites(): void {
        const me = this;

        const sites: Array<{ value: number; label: string; lastUpdate: Date }> = []
        this.setState({loading : true})
        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    for (let i = 0; i < data.length; i++) {
                        const siteId = parseInt(data[i].siteId);
                        const rec = {
                            value: siteId,
                            label: data[i].siteName,
                            lastUpdate: data[i].lastUpdate,
                        };
                        sites.push(rec);
                    }
                    
                    me.setState(
                        {
                            sites: sites,
                        });
                }
                else {
                    me.setState(
                        {
                            sites: [],
                        });
                    console.log("No sites");
                }
            },
                // reject() - API error
                () => {
                    me.setState(
                        {
                            sites: [],
                        });
                    console.log("API Error");

                })
            .catch(function () {
                console.log("API Error");
            });
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const device = this.state.devices.find((row) => row.id === id.key);

        //const site = {
        //    id: 0,
        //    SiteNameUserFriendly: device ? device.site : "",
        //    SiteName: "",
        //    created: new Date(),
        //    deleted: null,
        //    Latitude: device ? device.gpsLatitude : null,
        //    Longitude: device ? device.gpsLongitude : null,
        //    defaultSite: false,
        //    fkCompanyId: parseInt("0" + sessionStorage.getItem('companyId')),
        //    fkEASiteId: null,
        //    EASite: "",
        //    lastUpdate: null,
        //};

        this.setState(
            {
                tempDevice: device,
                //tempSite: site,
                anchorEl: event.currentTarget
            });

        //if (this.state.sites.length == 0) {
        //    this.getSites();
        //}


    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleChariotModal = (): void => {
        this.setState({
            chariotModal: !this.state.chariotModal
        });
    }

    //edit row
    editDevice = (): void => {
        this.setState({
            modal: !this.state.modal,
            anchorEl: null
        });
        modalTitle = "Edit Device"
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null
        });
        modalTitle = "Warning"
    }

    toggleImageModal = (): void => {
        modalTitle = "Image upload"
        this.setState({
            imageDialog: !this.state.imageDialog,
            anchorEl: null,
        });

    }    

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    deleteRow = (): void => {

        const me = this;

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/Logger?loggerId=' + this.state.tempDevice?.id), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    const rows = this.state.devices
                    const device = this.state.devices.find((device) => device.id === this.state.tempDevice?.id);
                    //remove the old version of the row
                    const filteredRows = rows.filter(function (row) { return row.id !== device?.id });


                    this.setState({
                        deleteModal: !this.state.deleteModal,
                        devices: filteredRows
                    });
                }
                else {
                    me.createErrorNotification();
                }
                
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });



    }

    //field changed events---
    deviceSiteChanged = (event: any): void => {
        const tempDevice = this.state.tempDevice;
        if (tempDevice) {
            tempDevice.site = event.label;
            tempDevice.siteId = event.value;
            this.setState({ tempDevice: tempDevice });
        }
    }

    //-------------------------

    //save changes, for both edit and new
    saveDevice = (): void => {

        const device = this.state.devices.find((device) => device.id === this.state.tempDevice?.id);
        const rows = this.state.devices

        if (device && this.state.tempDevice) {
            const deviceIndex = rows.indexOf(device);

            rows.splice(deviceIndex, 1, this.state.tempDevice);

            this.setState({
                modal: !this.state.modal,
                devices: rows
            });
        }

        //********************
        //send model to API


    }
    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
    }


    deviceOverviewClick = (): void => {
        
        this.setState(
            {
                redirect: true,
                //redirectPath: this.state.tempDevice?.model != "LCL" ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectPath: !isLCLLogger(this.state.tempDevice?.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectProps: { 
                    serial: this.state.tempDevice?.serial,
                    mapKey: this.props.mapKey
                        }
            });
    }

    deviceDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeviceDetail',
                redirectProps: {
                    serial: this.state.tempDevice?.serial
                }
            });
    }

    deviceConfigClick = (): void => {

        this.setState(
            {
                modal: !this.state.modal,
                anchorEl: null,
            });

    }

    chariotConfigClick = (): void => {
        if (this.state.tempDevice?.lastConnected == null) {

            NotificationManager.warning('No Configuration received from Logger', 'Cannot Configure');

            this.setState(
                {
                    anchorEl: null,
                });
       }
        else {
            this.setState(
                {
                    chariotModal: !this.state.chariotModal,
                    anchorEl: null,
                });

        }
    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
        localStorage.setItem('deviceListGrid', "")
    }


    toggleDeployModal = (): void => {
        this.setState({
            deployModal: !this.state.deployModal,
            anchorEl: null,
            fromDate: new Date()
        });
        if (this.state.tempDevice?.deployed == "Spare") {
            modalTitle = "Deploy device " + this.state.tempDevice?.serial;
        }
        else {
            modalTitle = "Terminate device deployment - " + this.state.tempDevice?.serial;
        }
    };

    fromDateChanged = (event: any): void => {
        this.setState({ fromDate: event.value });
    }
    /*
    deployConfirm = (): void => {

        const me = this;

        const loggerIds: Array<{ Id: number }> = [{ Id: this.state.tempDevice?.id || 0 }];
        if (this.state.tempDevice?.deployed == "Spare") {

            //save logger connected to site
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    siteId: this.state.tempDevice.siteId,
                    latUpdate: null,
                    loggers: loggerIds,
                    assignLogReadingsFromDate: this.state.fromDate,
                }),
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                .then(async response => {
                    if (response.status == 200) {
                        me.createSuccessNotification();
                        //UI update
                        me.reloadData(null);
                    }
                    else {
                        me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                });
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    siteId: this.state.tempDevice?.siteId,
                    LastUpdate: new Date(),
                    loggers: loggerIds,
                    assignLogReadingsFromDate: this.state.fromDate
                }),
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/DeassignLoggersFromSite'), requestOptions)
                .then(async response => {
                    if (response.status == 200) {

                            me.createSuccessNotification();
                            //UI update
                            me.reloadData(null);
                    }
                    else {
                        if (response.status == 409) {
                            me.createConflictNotification();
                            me.reloadData(this.state.tempDevice?.id || null);
                        }
                        else
                            me.createErrorNotification();
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification();
                    console.log(error);
                });
        }

        this.toggleDeployModal();
    }
    */
    dialogResponse(result: number, update: boolean): void {

        this.toggleDeployModal();
        if (result == 0) {
            this.createSuccessNotification();
            if (update) {
                //UI update - after 2 secs to allow server to update deployment
                setTimeout(this.reloadData.bind(this), 2000);
            }
        }
        else {
            this.createErrorNotification();
        }
    }


    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    alarmColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            if (cellInfo.value == 0)
                return "NONE";
            else
                return "ACTIVE (" + cellInfo.value + ")";
    }

    channelColumnCustomizeText(cellInfo: any): string {
        let display = "";
        if (cellInfo.value != null) {
            const channels = cellInfo.value.split(",");
            channels.map((v: string) => {
                if (v.substr(2).length > 0) {
                    display = display + v.substr(2) + "<br/>";
                }
            });
        }
        return display;
    }

   serialColumnTemplate(item: any): ReactNode {
        let error = ''
        let errorLabel = false
        if (item.data.dormant) {
           errorLabel = true
           error = 'Dormant'
           
            }
        else if (item.data.undeploy) {
           errorLabel = true
           error = 'Undeployed'
           
       }
        else if (item.data.sendToLogger && item.data.updateConfirmed === null) {
           errorLabel = true
           error = 'Config update needed'
           
       }
            else if (((item.data.firmwareToUpdate && item.data.firmwareToUpdate > 0) || (item.data.firmwareSent && item.data.firmwareSent > 0))
                && item.data.firmwareConfirmed === null) {
           errorLabel = true
           error = 'Firmware update needed'
           
       }
        else if (item.data.deployed == "Spare") {
           errorLabel = true
           error = 'Set as spare'


            }
       
       
        
        if (item == null)
            return <div></div>;
            else {
            return <div style={{display: "flex", position: "relative"} }>
                {errorLabel && <Tooltip title={error}>
                    <i className={"fa-solid fa-triangle-exclamation"} style={{ margin: "3px 10px 0 0", position: "absolute" }}></i>

                </Tooltip>}
                <p style={{ textAlign: "right", width: "100%" } }>{item.data.serial}</p>
                    </div>
        }
    }


    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Readings');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? 'ZONELOG Export.xlsx' : 'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    selectionChanged(data: any): void {
        
        this.setState({
            selectedItemKeys: data.selectedRowKeys,
            selected: data.selectedRowKeys
        });
        
        sessionStorage.setItem('selectedLoggers', data.selectedRowKeys.toString())
        this.props.handleSelectedLoggers(data.selectedRowKeys)
        
    }

    checkRow(e: any): void {
        const key = parseInt(e.target.value);
        const list = this.state.selected;
        const idx = list.indexOf(key)
        if (idx >= 0) {
            list.splice(idx, 1);
        }
        else {
            list.push(key)
        }
        this.setState({ selected: list });
    }

    toggleReportModal(): void {
        this.setState({ reportDialog: !this.state.reportDialog });
        // NOTE Test checks PREVIOUS state
        if (!this.state.reportDialog) {
            this.timer = setInterval(this.reportEnable.bind(this), 1000);
        }
        else {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    toggleSadborowReportModal(): void {
        this.setState({ reportSadborowDialog: !this.state.reportSadborowDialog });
        // NOTE Test checks PREVIOUS state
        if (!this.state.reportSadborowDialog) {
            this.timer = setInterval(this.reportEnable.bind(this), 1000);
        }
        else {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    reportEnable(): void {
        const enabled = true;

        this.setState({
            reportEnable: enabled
        });
        if (enabled) {
            clearInterval(this.timer);
            this.timer = null;

        }
    }

    graphLoggersClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/multiLoggerGraph',
                redirectProps: {
                    groupId: null,
                    loggers: this.state.selected,
                    loggerId: null,
                    showBackButton: true,
                }
            });
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    selectRender(key: dxDataGridRowObject): ReactNode {
        return (
            <Checkbox value={key.key} checked={this.state.selected.indexOf(key.key) >= 0} onChange={this.checkRow.bind(this)} />
            )
    }

    changeOfSelectionFromReport(numberToRemove: number): void {

        const updatedLoggers = this.state.selectedItemKeys.filter(number => number !== numberToRemove);
        sessionStorage.setItem('selectedLoggers', updatedLoggers.toString())
        this.setState({
            selectedItemKeys: updatedLoggers,
            selected: updatedLoggers
        })

    }

    favoriteToList = (id: number) => {
        let favorites;
        if (!this.state.savedFavorites.includes(id)) {
            favorites = [...this.state.savedFavorites, id];
        } else {
            favorites = this.state.savedFavorites.filter((favoriteId) => favoriteId !== id);
        }

        // Use the callback function to ensure the state is updated before proceeding
        this.setState({ savedFavorites: favorites }, () => {
            const favouriteString = this.state.savedFavorites.join();
            
            sessionStorage.setItem('favourites', favouriteString);
        });
    };

    clearSelectedLoggers = (): void => {
        this.setState({
            selectedItemKeys: [],
            selected: []
        })
        sessionStorage.setItem('selectedLoggers', '')
        this.props.handleSelectedLoggers([])
        this.props.onReloadData(this.state.devices)
        
    }

    FavoriteColumnTemplate: React.FC<FavoriteColumnTemplateProps> = (id) => {

        
        const deviceId = id.data.id
        return (
            <div style={{ marginLeft: "-20px", width: "40px", position: "absolute"}} onClick={(): void => { this.favoriteToList(deviceId); this.setState({ newFavourites: true }) }}>
                <i
                    className={this.state.savedFavorites.includes(deviceId) ? `fa-solid fa-star` : `far fa-star`}
                    style={this.state.savedFavorites.includes(deviceId) ? { color: '#ffd700' } : { color: '#143C67' }} 
                />
            </div>
        );
    };

    render(): ReactNode {
        const { classes } = this.props;

        return (
            (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
                (
                     (!this.state.redirect) ?
                        (<div>
                            <Dialog
                                fullScreen
                                open={this.state.modal}
                            >
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={(): void => this.toggleModal()}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                            {this.state.tempDevice?.model} Configuration
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                {this.state.tempDevice &&
                                        <ToolkitTOR
                                        serial={this.state.tempDevice?.serial}
                                        product={this.state.tempDevice?.model}
                                        model={this.state.tempDevice?.modelId}
                                        />
                                }
                            </Dialog>

                            <Dialog
                                fullScreen
                                open={this.state.chariotModal}
                            >
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={(): void => this.toggleChariotModal()}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                            {window.location.hostname.includes('zonelog.net') ? "ZONELOG Configuration" : "Chariot Configuration"}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                {this.state.tempDevice &&
                                    <ToolkitCHR
                                    serial={this.state.tempDevice?.serial}
                                    product={this.state.tempDevice?.model}
                                    model={this.state.tempDevice?.modelId}
                                    channels={this.state.tempDevice?.channelsAvail}
                                    meterConfig={this.state.tempDevice?.meterConfig}
                                    />
                                }
                            </Dialog>

                            {this.state.tempDevice?.deployed == "Spare" ?
                                <DeployDialog
                                    show={this.state.deployModal}
                                    title={modalTitle}
                                    site={this.state.tempSite}
                                    loggerId={this.state.tempDevice?.id}
                                    configurationId={this.state.tempDevice?.configurationId}
                                    onSubmit={this.dialogResponse.bind(this)}
                                    onCancel={this.toggleDeployModal.bind(this)}

                                />
                            :
                                <UndeployDialog
                                    show={this.state.deployModal}
                                    title={modalTitle}
                                    loggerId={this.state.tempDevice ? [this.state.tempDevice.id] : []}
                                    onSubmit={this.dialogResponse.bind(this)}
                                    onCancel={this.toggleDeployModal.bind(this)}
                                    isChariot={this.state.tempDevice ? this.state.tempDevice.id > 500000 : false}
                                />
                            }



                            
                        <Modal
                            show={this.state.deleteModal}
                            onHide={(): void => this.toggleDeleteModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                                >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                Delete row?
                            </Modal.Body>
                            <Modal.Footer>
                                    <MaterialButton color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.deleteRow()}>Continue</MaterialButton>
                                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</MaterialButton>
                            </Modal.Footer>
                        </Modal>

                            <UploadDialog
                                show={this.state.imageDialog}
                                title={modalTitle}
                                siteId={0}
                                loggerId={this.state.tempDevice ? this.state.tempDevice.id : 0}
                                onSubmit={this.toggleImageModal.bind(this)}
                                onCancel={this.toggleImageModal.bind(this)}
                            />

                            <DeviceReportDialog
                                show={this.state.reportDialog}
                                title="Generate Report for Loggers on Sites in Group"
                                loggerIds={this.state.selected}
                                removeLogger={this.changeOfSelectionFromReport}
                                onCancel={this.toggleReportModal.bind(this)}
                                deviceData={this.state.devices}
                                fromGroup={false}
                                templateClicked={this.state.templateClicked}
                            />

                            {/*<LoggerReportDialog*/}
                            {/*    show={this.state.reportDialog}*/}
                            {/*    title="Generate Report for Loggers on Sites in Group"*/}
                            {/*    loggerIds={this.state.selected}*/}
                            {/*    onSubmit={this.toggleReportModal.bind(this)}*/}
                            {/*    onCancel={this.toggleReportModal.bind(this)}*/}
                            {/*    deviceData={this.state.devices}*/}
                            {/*/>*/}

                            <ConsumptionReport
                                show={this.state.reportSadborowDialog}
                                title="Consumption Report"
                                loggers={this.state.selected}
                                buttonClick={this.state.reportSadborowDialog}
                                onCancel={this.toggleSadborowReportModal.bind(this)}
                                deviceData={this.state.devices}
                            />

                            {/*
                        <Modal
                            show={this.state.deployModal}
                            onHide={(): void => this.toggleDeployModal()}
                            dialogClassName="modal-100w"
                            aria-labelledby="example-custom-modal-styling-title">
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                    {this.state.tempDevice?.deployed == "Spare" ?
                                        <div>
                                            Logger is not currently deployed - please select a site the logger is deployed to, and date deployed.<br/>
                                            If the site is not listed, visit Sites and create new site and allocate logger there.
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                        Site:&nbsp;
                                                        <Select
                                                            
                                                            options={this.state.sites}
                                                            defaultValue={this.state.tempDevice.site}
                                                            onChange={this.deviceSiteChanged}
                                                        />

                                                </Grid>
                                                <Grid item xs={12}>
                                                    Assign from:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    :
                                        <div>
                                            This logger is currently deployed to site {this.state.tempDevice?.siteName}. Please confirm it has been removed and will be marked as unallocated.<br/>
                                            Readings will no longer be associated with the site.
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                       Removal date:&nbsp;<DateBox id="fromDate" displayFormat={"dd/MM/yyyy"} value={this.state.fromDate} onValueChanged={this.fromDateChanged.bind(this)} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                            </Modal.Body>
                            <Modal.Footer>
                                    <MaterialButton color="primary" onClick={(): void => this.deployConfirm()}>Confirm</MaterialButton>
                                    <MaterialButton color="secondary" onClick={(): void => this.toggleDeployModal()}>Cancel</MaterialButton>
                            </Modal.Footer>
                        </Modal>
                            */}

                        {this.state.loading &&
                            <div style={{
                                position: 'absolute', left: '50%', top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <ClipLoader
                                    size={150}
                                    color={"#123abc"}
                                    loading={this.state.loading}
                                />
                            </div>
                        }
                        {!this.state.loading &&
                            <div>
                                    
                                {this.state.tableHidden &&
                                        <ApiFailed />
                                }
                                    {!this.state.tableHidden &&
                                        <div>

                                        <div style={{marginTop: "-15px"} }>
                                                <GridContainer>
                                                    <div>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Card>
                                            <CardHeader color="primary">
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <div style={{ display: 'inline', float: "right" }}>
                                                            <Tooltip title="clear sort/filter">
                                                                <IconButton
                                                                    edge="start"
                                                                    color="inherit"
                                                                    onClick={(): void => this.clearState()}
                                                                    aria-label="clear sort/filter"
                                                                >
                                                                    <SettingsBackupRestoreRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="refresh data">
                                                                <IconButton
                                                                    edge="start"
                                                                    color="inherit"
                                                                    onClick={(): void => this.reloadData(null)}
                                                                    aria-label="refresh"
                                                                >
                                                                    <RefreshRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                    </div>
                                                                    <h4 className={classes.cardTitleWhite} style={{ width: 300, paddingLeft: "7px" }}>Devices</h4>
                                                                    <p className={classes.cardCategoryWhite} style={{ width: 300, paddingLeft: "7px" }}>
                                                            Devices identified with the server
                                                        </p>
                                                                        </GridItem>
                                                                        {this.state.selected.length === 0 && <GridItem xs={12} sm={12} md={12}>
                                                                            <div style={{ display: 'inline', float: "right", margin: "0 10px 10px 0" }}>
                                                                                <table style={{ width: '70%', borderSpacing: '50px', paddingTop: "10px" }}>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                                                                {this.state.reportsAvail && <Button variant="contained" color="primary" onClick={(): void => { this.toggleReportModal(); this.setState({templateClicked: true}) }} >Load Report Template</Button>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </GridItem>}                                                                                            
                                                    {this.state.selected.length > 0 &&
                                                    <GridItem xs={12} sm={12} md={12}>
                                                                                <div style={{ display: 'inline', float: "right", margin: "0 10px 10px 0"}}>
                                                            <table style={{ width: '70%', borderSpacing: '50px', paddingTop: "10px" }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                                            <Button variant="contained" color="primary" onClick={(): void => this.graphLoggersClick()} >Graph Loggers</Button>
                                                                        </td>
                                                                        <td>&nbsp;&nbsp;&nbsp;</td>
                                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                                                                    <Button variant="contained" color="primary" onClick={(): void => { this.toggleReportModal(); this.setState({ templateClicked: false }) }} >Create Report For Loggers</Button>
                                                                        </td>
                                                                        <td>&nbsp;&nbsp;&nbsp;</td>
                                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                                            <Button variant="contained" color="primary" onClick={this.state.selected.length > 0 ? this.toggleSadborowReportModal.bind(this) : (): void => alert("Please select at least one logger")} >View Consumption</Button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </GridItem>
                                                }
                                                                
                                                </GridContainer>
                                                </CardHeader>
                                                    <CardBody>
                                                     <div>
                                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                            {sessionStorage.getItem('selectedLoggers') && <Button variant="contained" style={{ marginBottom: "3px" }} onClick={(): void => this.clearSelectedLoggers()}>Clear Selected Loggers</Button>}
                                                            <div style={{ textAlign: 'right', marginTop: "5px" }}><small>select devices in right hand column for graphs or reports</small></div>
                                                        </div>
                                                    <div>
                                                                            
                                                    <DataGrid
                                                    allowColumnResizing={true}
                                                    allowColumnReordering={true}
                                                    dataSource={this.state.devices}
                                                    keyExpr="id"
                                                    ref={this.gridRef}
                                                    defaultFilterValue={this.state.filterValue}
                                                    onExporting={this.onExporting}
                                                    selectedRowKeys={this.state.selectedItemKeys}
                                                    onSelectionChanged={this.selectionChanged}
                                                                            >
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode='allPages'
                                                        showCheckBoxesMode='always' //'onClick'
                                                    />
                                                    {/*<StateStoring enabled={true} type="localStorage" storageKey="deviceListGrid" />*/}
                                                                        
                                                    <Export enabled={true} />
                                                    <GroupPanel visible={true} />
                                                                        <Grouping autoExpandAll={sessionStorage.getItem('userLevel') == "identityadmin" ? false : true} />
                                                    <FilterRow visible={true} />
                                                    <HeaderFilter visible={true} allowSearch={true} />
                                                                                     
                                                                                <GridColumn dataField="id" visible={false} dataType="number" />
                                                                                
                                                    {this.state.showCompany &&
                                                    <GridColumn dataField="company" groupIndex={0} />                    }
                                                    <GridColumn fixedPosition="left" width={25} fixed={true} cellRender={(id):void => this.FavoriteColumnTemplate(id)} />
                                                    <GridColumn dataField="siteName" caption="Site Name"/>
                                                    <GridColumn dataField="siteRef" caption="Site Ref" />
                                                    <GridColumn dataField="site" dataType="string" caption="Device Name" />
                                                    <GridColumn dataField="model" dataType="string" />
                                                    <GridColumn dataField="serial" dataType="string"   cellRender={this.serialColumnTemplate}/>
                                                    <GridColumn dataField="lastConnected" dataType="datetime" customizeText={this.dateColumnCustomizeText} caption="Last Connected" allowHeaderFiltering={false} format="shortDate" />
                                                    <GridColumn dataField="channels" dataType="string" allowFiltering={true} allowSorting={false} customizeText={this.channelColumnCustomizeText} encodeHtml={false} />
                                                    <GridColumn type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" caption="Actions" width={50} />
                                                    <GridColumn type="selection" caption="Select" fixed={true} fixedPosition="right" />

                                                    <GridColumn visible={false} type="buttons" cellRender={this.selectRender.bind(this)} fixed={true} fixedPosition="right" caption="Select" width={50} />
                                                                                
                                                    <Pager allowedPageSizes={[5, 10, 20, 50]} showPageSizeSelector={true} />
                                                    <Paging defaultPageSize={10} />
                                                                                                   
                                                                    </DataGrid>
                                                                </div>
                                                     
                                                    </div>
                                                </CardBody>
                                            </Card>
                                                        </GridItem>
                                                    </div>
                                            </GridContainer>
                                            </div>
                                    </div>
                                }
                                </div> 

                            }
                            
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            disableScrollLock={true}
                            >
                                <MenuItem onClick={this.deviceOverviewClick}>Overview</MenuItem>

                                {/*{!isLCLLogger(this.state.tempDevice?.model) &&*/}
                                {/*    <MenuItem onClick={this.deviceDetailsClick}>Details</MenuItem>*/}
                                {/*}*/}
                                {!this.state.tempDevice?.undeploy &&
                                    <MenuItem onClick={this.toggleDeployModal}>
                                        {this.state.tempDevice?.deployed == "Spare" ? "Assign" : "Un-Assign"}
                                    </MenuItem>
                                }
                                {isLCLLogger(this.state.tempDevice?.model) && (sessionStorage.getItem('userLevel') != "user" && sessionStorage.getItem('userLevel') != "restricteduser") &&
                                    <MenuItem onClick={this.chariotConfigClick}>Configure</MenuItem>
                                }
                                {(this.state.tempDevice?.model == "TOR-N" || this.state.tempDevice?.model == "SWG" || this.state.tempDevice?.model == "TOR-S" || this.state.tempDevice?.model == "TOR-R" || this.state.tempDevice?.model == "Textlog")  &&
                                    <MenuItem onClick={this.deviceConfigClick}>Configure</MenuItem>
                                }
                                <MenuItem onClick={this.toggleImageModal}>Upload Picture</MenuItem>
                                {this.state.tempDevice?.deployed == "Spare" && (sessionStorage.getItem('userLevel') != "user" && sessionStorage.getItem('userLevel') != "restricteduser") &&
                                    <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>
                                }
                                
                        </Menu>

                        <NotificationContainer />
                    </div>)
                    :
                     (<Redirect push to={{
                        pathname: this.state.redirectPath,
                        state: this.state.redirectProps
                    }}/>)
                )
                :
                (
                    <Redirect to={'/Login'} />
                )
        )
    }
}

export default withStyles(style)(DeviceList);

